/* ---------------------------------
Hero
--------------------------------- */

import { Box, Container, Flex } from "@chakra-ui/react";
import {
  Length,
  ResponsiveValue,
} from "@chakra-ui/styled-system/dist/types/utils";
import * as CSS from "csstype";
import React from "react";
import { BoxProps } from "@chakra-ui/layout/dist/types/box";

type OwnProps = {
  bgImage?: string;
  bgColor?: string;
  textColor?: string;
  h?: ResponsiveValue<CSS.Property.Height<Length>>;
  breadcrumb?: React.ReactNode;
} & BoxProps;

const Hero: React.FC<OwnProps> = ({
  bgImage,
  bgColor = "accent.01",
  textColor,
  breadcrumb,
  h = [460, 500],
  children,
  ...boxProps
}) => {
  const background =
    (bgImage && `url(${bgImage}) #eee center / cover no-repeat`) || bgColor;
  return (
    <>
      <Box as={"section"} id={"hero"} h={h} bg={background} {...boxProps}>
        <Flex align={"center"} justify={"center"} height={"full"}>
          <Box
            textAlign={"center"}
            px={2}
            fontSize={["lg", "xl"]}
            textColor={textColor}
          >
            {children}
          </Box>
        </Flex>
      </Box>

      {breadcrumb ? (
        <Box bg={bgColor}>
          <Container maxW="container.xl" textColor={textColor}>
            {breadcrumb}
          </Container>
        </Box>
      ) : null}
    </>
  );
};

export default Hero;
